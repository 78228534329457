<script setup lang="ts">
const { t, locale } = useI18n()

const sitedata: any = useSiteData()

const section10 = computed(() => sitedata.value?.layout1_home_data?.section10)
</script>

<template>
  <!-- Start block -->
  <section class="bg-gray-50 dark:bg-gray-800">
    <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
      <div class="max-w-screen-sm mx-auto text-center">
        <h2 class="mb-4 text-4xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
          {{ section10?.text1 ?? t('contact_us') }}
        </h2>
        <p class="mb-10 font-light text-gray-800 dark:text-gray-400 text-xl">
          {{ section10?.text2 }}
        </p>
        <NuxtLinkLocale
          :to="section10?.button1?.href ?? '#'"
          :hreflang="locale"
          class="inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-center text-white border border-gray-200 rounded-lg sm:w-auto bg-sky-500 hover:bg-sky-400 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
        >
          {{ section10?.button1?.text ?? t('contact') }}
          <svg class="w-4 h-4 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 16 4-4-4-4m6 8 4-4-4-4" />
          </svg>
        </NuxtLinkLocale>
      </div>
    </div>
  </section>
  <!-- End block -->
</template>
