<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const sitedata: any = useSiteData()
</script>

<template>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
      <div class="lg:pr-8 pt-15 sm:pt-10 sm:text-center">
        <div class="lg:max-w-lg">
          <ContentView v-if="sitedata?.content" :content="sitedata?.content" />
        </div>
      </div>

      <div class="rounded-2xl shadow-2xl ring-1 ring-slate-900/5 px-2 py-6 sm:py-8 lg:px-4 mt-10">
        <div class="flex flex-col mx-auto max-w-screen-md text-center mb-4 lg:mb-6">
          <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {{ sitedata?.name }}
          </h2>
          <p class="mt-6 text-lg leading-8 text-gray-600">
            {{ sitedata?.summary }}
          </p>
        </div>
        <FormInput
          :columns="props?.data?.columns"
          class="mx-auto mt-5 max-w-xl px-5"
        />
      </div>
    </div>
  </div>
</template>
