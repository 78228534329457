<script setup lang="ts">
const { t } = useI18n()

const sitedata: any = useSiteData()

const section8 = computed(() => sitedata.value?.layout1_home_data?.section8)

const items = computed(() => sitedata.value?.layout1_home_data?.section8?.list1 ?? [])

// const items = [{
//   label: 'PROVIEW hoạt động trong những lĩnh vực nào?',
//   defaultOpen: true,
//   content: 'PROVIEW là một tập đoàn đa ngành, hoạt động trên nhiều lĩnh vực khác nhau: CNTT phần mềm, Tự động hóa, Công nghệ Chip, Điện, điện tử, Thương mại Xuất nhập khẩu, Đầu tư, Xúc tiến đầu tư, Tư vấn, Y tế, Dược, Nông nghiệp công nghệ cao, Sản xuất thông minh. Sự đa dạng này giúp chúng tôi mang đến cho khách hàng những giải pháp toàn diện và chất lượng cao.',
// }, {
//   label: 'Tầm nhìn và sứ mệnh của PROVIEW là gì?',
//   content: 'Tầm nhìn của chúng tôi là trở thành một trong những tập đoàn hàng đầu, góp phần vào sự phát triển bền vững của cộng đồng. Sứ mệnh của chúng tôi là không ngừng đổi mới, sáng tạo để mang đến những sản phẩm và dịch vụ tốt nhất, đáp ứng nhu cầu đa dạng của khách hàng.',
// }, {
//   label: 'Điểm mạnh cốt lõi của PROVIEW là gì?',
//   content: 'Điểm mạnh cốt lõi của chúng tôi là Quy mô đa dạng hóa, Năng lực tài chính vững mạnh, Nguồn nhân lực chất lượng cao, Hệ thống quản lý hiện đại, Mạng lưới phân phối rộng khắp, Khả năng đổi mới và sáng tạo, Uy tín thương hiệu cao và Mối quan hệ đối tác rộng rãi. Nhờ đó, chúng tôi luôn dẫn đầu trong việc tạo ra những giá trị mới và đáp ứng sự kỳ vọng của khách hàng.',
// }, {
//   label: 'PROVIEW có những chính sách gì để đảm bảo chất lượng sản phẩm và dịch vụ?',
//   content: 'PROVIEW luôn đặt chất lượng lên hàng đầu. Chúng tôi áp dụng hệ thống quản lý chất lượng và không ngừng cải tiến quy trình sản xuất, dịch vụ để đảm bảo sản phẩm và dịch vụ luôn đạt tiêu chuẩn cao nhất.',
// }, {
//   label: 'Làm thế nào để liên hệ với PROVIEW?',
//   content: 'Quý khách có thể liên hệ với chúng tôi qua số điện thoại: +84 705 019 688, địa chỉ email: kinhdoanh@proview.vn hoặc trực tiếp đến văn phòng của chúng tôi. Chúng tôi luôn sẵn sàng hỗ trợ quý khách.',
// }, {
//   label: 'PROVIEW có các chương trình trách nhiệm xã hội nào?',
//   content: 'PROVIEW luôn ý thức được trách nhiệm của mình đối với cộng đồng. Chúng tôi đã và đang triển khai nhiều chương trình xã hội nhằm đóng góp vào sự phát triển bền vững của xã hội.',
// }]
</script>

<template>
  <!-- Start block -->
  <section class="bg-white dark:bg-gray-900">
    <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
      <h2 class="mb-6 text-4xl font-extrabold tracking-tight text-center text-gray-900 lg:mb-8 dark:text-white">
        {{ section8?.text1 ?? t('frequently_asked_questions') }}
      </h2>
      <div class="max-w-screen-md mx-auto">
        <UAccordion :items="items" size="xl" :ui="{ wrapper: 'flex flex-col w-full', item: { size: 'text-xl', base: 'font-light', color: 'text-gray-800', padding: 'px-2.5' } }">
          <template #default="{ item, open }">
            <UButton color="gray" variant="ghost" class="border-b border-gray-200 dark:border-gray-700" :ui="{ rounded: 'rounded-none' }">
              <span class="truncate w-full py-5 text-xl font-medium text-left text-gray-800">{{ item.label }}</span>

              <template #trailing>
                <UIcon
                  name="i-heroicons-chevron-right-20-solid"
                  class="w-5 h-5 ms-auto transform transition-transform duration-200"
                  :class="[open && 'rotate-90']"
                />
              </template>
            </UButton>
          </template>
        </UAccordion>
      </div>
    </div>
  </section>
  <!-- End block -->
</template>
